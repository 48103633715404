import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useGoogleLogin } from '@react-oauth/google';
import { useSWRConfig } from 'swr';
import type { Nullable } from 'types/common';
import { isError } from 'types/predicates';

import { isInvalidCredentialsError, setAuth } from 'services/auth-new';

import logSentryError from 'utils/sentry';

import { useGoogleSigninMutation, useLegacySigninMutation } from 'dux/api/auth';

import useNextFromLocationSearchString from '../useNextFromLocationSearchString';
import type { SigninForm } from './types';
import getAuthError from './utils';

// todo: ensure equivalency with the old thunk/AuthService
export const useGoogleAuthLogin = () => {
  const navigate = useNavigate();
  const next = useNextFromLocationSearchString() ?? '';

  const [googleSignin] = useGoogleSigninMutation();
  const [errorGoogleMessage, setErrorGoogleMessage] = useState<string | undefined>('');

  const login = useGoogleLogin({
    onSuccess: async ({ code }) => {
      try {
        // todo: force it to throw here
        // todo: should I set error message to '' here before all?
        const auth = await googleSignin(code).unwrap();
        setAuth(auth);
        navigate(next);
      } catch (err) {
        console.log('=== err onSuccess', err);
        // todo: which fields does error have?
        if (!isError(err) || typeof err !== 'string') return;
        setErrorGoogleMessage(err);
        logSentryError('[SignIn] GoogleSignin', err); // todo: might be getErrorMessage(err) and not err
      }
    },
    onError: ({ error_description }) => {
      console.log('=== err onError', error_description);
      setErrorGoogleMessage(error_description);
      // todo: should I log here to Sentry, too?
    },
    flow: 'auth-code',
  });

  return { login, errorGoogleMessage };
};

export const useLegacyLogin = () => {
  const navigate = useNavigate();
  const next = useNextFromLocationSearchString() ?? '';
  const [legacySignIn, { status }] = useLegacySigninMutation();
  const [errorMessage, setErrorMessage] = useState<Nullable<string> | undefined>(null);

  const { mutate } = useSWRConfig();

  const login = async (e: React.FormEvent<HTMLFormElement> & SigninForm) => {
    e.preventDefault();
    try {
      setErrorMessage(null);
      await mutate(() => true, undefined, false); // Clear all the cache. SWR will revalidate upon re-render.
      const username = e.target?.username.value;
      const password = e.target?.password.value;
      const auth = await legacySignIn({ username, password }).unwrap();
      setAuth(auth);
      navigate(next);
    } catch (err) {
      const errorMsg = getAuthError(err);
      setErrorMessage(errorMsg);
      if (!isInvalidCredentialsError(err)) {
        logSentryError('[Signin hooks] Legacy signin', errorMsg);
      }
    }
  };

  return { login, fetchingStatus: status, errorMessage };
};
