import { createAsyncThunk } from '@reduxjs/toolkit';

import assign from 'lodash/fp/assign';
import map from 'lodash/fp/map';
import toString from 'lodash/fp/toString';

import type { RootState } from 'store';
import type { Pages } from 'types/common';
import { isError, isHTTPError } from 'types/predicates';

import initApiClient from 'services/ApiClient';
import { getUserId } from 'services/auth-new';

import logSentryError from 'utils/sentry';

import type { EnhancedTeammate, FetchedTeammate, TeammatesError } from './types';

const clearerErrorDetail = {
  'User is inactive': 'This user is inactive, please see a manager to activate your account.',
};

export const fetchSignedInTeammate = createAsyncThunk<
  FetchedTeammate,
  void,
  { state: RootState; rejectWithValue: TeammatesError }
>('teammates/fetchSignedInTeammate', async (_, { dispatch, rejectWithValue }) => {
  try {
    const APIClient = initApiClient(dispatch);
    const payload = await APIClient.get(`/v1/backoffice/teammates/${getUserId()}`);
    const signedInTeammate = await payload.json();
    return signedInTeammate;
  } catch (err) {
    if (!isError(err)) return '';
    logSentryError('[fetchSignedInTeammate]', err);

    const rejectedCommon = {
      kind: 'fetchSignedInTeammate',
      message: toString(err),
    };

    const rejectedSpecific = isHTTPError(err)
      ? {
          body: {
            ...err?.body,
            detail: clearerErrorDetail[err?.body?.detail ?? ''] ?? err?.body?.detail,
          },
        }
      : {};
    return rejectWithValue({ ...rejectedCommon, ...rejectedSpecific });
  }
});

export const fetchTeammates = createAsyncThunk<
  {
    teammates: EnhancedTeammate[];
    pages: Pages;
  },
  string,
  { state: RootState; rejectWithValue: TeammatesError }
>('teammates/fetchTeammates', async (urlToFetch, { dispatch, rejectWithValue }) => {
  try {
    const APIClient = initApiClient(dispatch);
    const query = await APIClient.get(urlToFetch);
    const result: FetchedTeammate[] = await query.json();
    const { pages } = query;
    const teammates: EnhancedTeammate[] = map((item: FetchedTeammate) =>
      assign(item, { mode: 'production' })
    )(result);
    return { teammates, pages };
  } catch (err) {
    logSentryError('[fetchTeammates]', err);
    return rejectWithValue({
      kind: 'fetchTeammates',
      message: toString(err),
    });
  }
});
