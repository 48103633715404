import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import type { AuthTokens } from 'services/auth-new/types';

import getEnv from 'utils/getEnv';

type GoogleResponse = Record<'token' | 'refresh', string>;
type LegacyAuthArgs = Record<'username' | 'password', string>;
type LegacyAuthResponse = Record<'access' | 'refresh', string>;

const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({ baseUrl: getEnv('REACT_APP_ROOT_URL') }),
  endpoints: builder => ({
    // todo: is baseUrl correct here? (see the inspector)
    googleSignin: builder.mutation<AuthTokens, string>({
      query: code => ({
        url: '/v1/backoffice/auth/social/jwt-pair/google-oauth2',
        method: 'POST',
        body: { code }, // todo: is it sent correctly? (see the inspector)
      }),
      transformResponse: (response: GoogleResponse) => {
        console.log('=== response: ', response);
        return { accessToken: response.token, refreshToken: response.refresh }; // todo: check me (see console.log)
      },
    }),
    legacySignin: builder.mutation<AuthTokens, LegacyAuthArgs>({
      query: ({ username, password }) => ({
        url: '/v1/auth/token/',
        method: 'POST',
        referrerPolicy: 'unsafe-url',
        body: { type: 'staff', username, password },
      }),
      transformResponse: (result: LegacyAuthResponse) => {
        return { accessToken: result.access, refreshToken: result.refresh };
      },
    }),
  }),
});

export default authApi;

export const {
  useGoogleSigninMutation,
  useLegacySigninMutation,
  util: { resetApiState: resetAuthApiState },
} = authApi;
