import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import jwtDecode from 'jwt-decode';
import type { Nullable } from 'types/common';

import type { AuthApiError } from 'Scenes/SignIn/utils';

import type { AuthTokens, DecodedJWT } from './types';

const AUTH_KEY = 'authentication';

export const signout = () => {
  const settings = localStorage.getItem('settings');
  localStorage.clear();

  if (settings) {
    localStorage.setItem('settings', settings);
  }
};

export const getAuth = (): Partial<AuthTokens> => {
  const value = localStorage.getItem(AUTH_KEY);
  return value ? JSON.parse(value) : {};
};

export const setAuth = (auth: AuthTokens) => {
  localStorage.setItem(AUTH_KEY, JSON.stringify(auth));
};

export const isSignedIn = () => {
  const { accessToken, refreshToken } = getAuth();
  return Boolean(accessToken) && Boolean(refreshToken);
};

const getDecodedTokenUnmemoized = (token?: string): Nullable<DecodedJWT> => {
  try {
    return jwtDecode(token);
  } catch {
    return null;
  }
};

type MemoizedFunction = typeof getDecodedTokenUnmemoized;
type FunctionParam = Parameters<MemoizedFunction>[0];

const memoizeOne = (func: MemoizedFunction) => {
  const cache = new Map<FunctionParam, ReturnType<MemoizedFunction>>();

  return (token: FunctionParam) => {
    if (cache.has(token)) return cache.get(token); // return cached value

    const value = func(token); // calculate the new value
    cache.clear(); // purge the cache because we want to save only one value
    cache.set(token, value);
    return value;
  };
};

const getDecodedToken = memoizeOne(getDecodedTokenUnmemoized);

export const getUserId = () => {
  const { accessToken } = getAuth();

  const decoded = getDecodedToken(accessToken);
  return decoded?.user_id;
};

export const isTokenExpired = (token?: string) => {
  try {
    const payload = jwtDecode(token);
    const epoch = Math.round(Date.now() / 1000);
    return payload.exp <= epoch;
  } catch (err) {
    return true;
  }
};

export const isInvalidCredentialsError = (err: unknown) => {
  const errAsFetchBaseQuery = err as FetchBaseQueryError;
  return (
    (errAsFetchBaseQuery?.data as AuthApiError)?.non_field_errors?.[0] ===
    'No active account found with the given credentials'
  );
};
