import { type AnyAction, combineReducers, type Reducer } from '@reduxjs/toolkit';
import type { RouterState } from 'redux-first-history';

import { LOGOUT_RESET_NON_RTK_QUERY_STATE } from 'hooks/reset-store';

import adminPanelReducer from 'dux/adminPanel/slice';
import authApi from 'dux/api/auth';
import backofficeApi from 'dux/api/backoffice';
import BoxesReducer from 'dux/boxes/slice';
import bufferSpacesReducer from 'dux/bufferSpaces/slice';
import countriesReducer from 'dux/countries/slice';
import couponsReducer from 'dux/coupons/slice';
import customersReducer from 'dux/customer/slice';
import exportsReducer from 'dux/exports/slice';
import FastConsultationReducer from 'dux/fastconsultation/slice';
import featureFlagsReducer from 'dux/featureFlags/slice';
import labInfosReducer from 'dux/lab-infos/slice';
import metadataReducer from 'dux/metadata/slice';
import ordersReducer from 'dux/orders/slice';
import paginationReducer from 'dux/pagination/slice';
import partialRefundReducer from 'dux/partialRefund/slice';
import productionReducer from 'dux/production/reducers';
import settingsReducer from 'dux/settings/slice';
import subscriptionsReducer from 'dux/subscriptions/slice';
import surveysReducer from 'dux/surveys/slice';
import teammatesReducer from 'dux/teammates/slice';
import variantsReducer from 'dux/variants/slice';

type RouterReducer = Reducer<RouterState>;

const authApiReducer = authApi.reducerPath;
const backofficeApiReducer = backofficeApi.reducerPath;

const createAppReducer = (routerReducer: RouterReducer) =>
  combineReducers({
    [authApiReducer]: authApi.reducer,
    [backofficeApiReducer]: backofficeApi.reducer,
    router: routerReducer,
    adminPanel: adminPanelReducer,
    boxes: BoxesReducer,
    bufferSpaces: bufferSpacesReducer,
    countries: countriesReducer,
    coupons: couponsReducer,
    customers: customersReducer,
    exports: exportsReducer,
    fastconsultation: FastConsultationReducer,
    featureFlags: featureFlagsReducer,
    metadata: metadataReducer,
    orders: ordersReducer,
    pagination: paginationReducer,
    partialRefund: partialRefundReducer,
    production: productionReducer,
    settings: settingsReducer,
    labInfos: labInfosReducer,
    subscriptions: subscriptionsReducer,
    surveys: surveysReducer,
    teammates: teammatesReducer,
    variants: variantsReducer,
  });

type AppReducer = typeof createAppReducer;
type State = Parameters<ReturnType<AppReducer>>[0];

const createRootReducer = (routerReducer: RouterReducer) => (state: State, action: AnyAction) => {
  const appReducer = createAppReducer(routerReducer);

  if (action.type === LOGOUT_RESET_NON_RTK_QUERY_STATE) {
    // preserve only RTK Query state which will be reset separately
    const newState = {
      [authApiReducer]: state?.[authApiReducer],
      [backofficeApiReducer]: state?.[backofficeApiReducer],
    } as State;

    return appReducer(newState, action);
  }

  return appReducer(state, action);
};

export default createRootReducer;
