import logSentryError from 'utils/sentry';

import AuthError from '../Errors/AuthError';
import AuthRefreshError from '../Errors/AuthRefreshError';
import HTTPError from '../Errors/HTTPError';
import { getAuth, isTokenExpired, setAuth } from './auth-new';
import * as SimpleApiClient from './SimpleApiClient';

const refresh = async () => {
  let auth = null;

  try {
    auth = getAuth();
  } catch (e) {
    logSentryError('[Auth Service refresh] error', e);
    throw new AuthRefreshError('Cannot refresh token, must signin');
  }

  if (auth === null) {
    throw new AuthError('Unauthenticated');
  }
  if (!auth.refreshToken || isTokenExpired(auth.refreshToken)) {
    throw new AuthRefreshError('Cannot refresh token, must signin');
  }
  const params = { refresh: auth.refreshToken };
  let response = null;
  try {
    response = await SimpleApiClient.post('/v1/auth/token/refresh/', params);
    if (!response.ok) {
      throw new HTTPError(response.status, response.statusText);
    }
  } catch (err) {
    throw new AuthRefreshError('Cannot refresh token, must signin');
  }
  const result = await response.json();
  auth.accessToken = result.access;
  setAuth(auth);
  return auth;
};

export default refresh;
