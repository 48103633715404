import { resetAuthApiState } from 'dux/api/auth';
import { resetBackofficeApiState } from 'dux/api/backoffice';

import { useOmsDispatch } from './rtk';

export const LOGOUT_RESET_NON_RTK_QUERY_STATE = 'LOGOUT_RESET_NON_RTK_QUERY_STATE';

export const useResetStore = () => {
  const dispatch = useOmsDispatch();

  return () => {
    // reset non-rtk query state
    dispatch({ type: 'LOGOUT_RESET_NON_RTK_QUERY_STATE' });

    // reset rtk query state
    dispatch(resetAuthApiState());
    dispatch(resetBackofficeApiState());
  };
};
