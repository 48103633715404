import { isError } from 'types/predicates';

import { isSerializedError } from './types';

type ErrorMessage = string | undefined;

export type AuthApiError = {
  non_field_errors: [string];
};

const isRegularObject = (arg: unknown): arg is { error?: string; data?: AuthApiError } =>
  typeof arg === 'object' && !Array.isArray(arg) && arg !== null;

const getAuthErrorMessage = (err: unknown): ErrorMessage => {
  if (isError(err) || isSerializedError(err)) return err.message;

  const fallback = 'Error during sign in';

  if (!isRegularObject(err)) return fallback;

  if ('error' in err) return err.error;

  return err.data?.non_field_errors?.[0] ?? fallback;
};

export default getAuthErrorMessage;
