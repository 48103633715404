import React, { useEffect, useState } from 'react';

import { Link, useLocation, useNavigate } from 'react-router-dom';

import map from 'lodash/fp/map';

import AccountIcon from '@mui/icons-material/AccountCircle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useResetStore } from 'hooks/reset-store';
import { useOmsDispatch, useOmsSelector } from 'hooks/rtk';
import type { Nullable } from 'types/common';

import { getUserId, signout } from 'services/auth-new';

import VIEW_MODE from 'constants/viewModes';

import constructMenu from 'utils/constructMenu';
import getEnv from 'utils/getEnv';

import * as ProductionActions from 'dux/production/actions';
import * as FeatureFlagSelectors from 'dux/featureFlags/selectors';
import getSectionTitle from 'dux/metadata/selectors';
import * as SettingsSelectors from 'dux/settings/selectors';
import * as TeammatesSelectors from 'dux/teammates/selectors';
import * as TeammatesActions from 'dux/teammates/thunks';

import {
  AppBarInfo,
  AppBarSearch,
  AppBarSectionTitle,
  AppBarVersionInfo,
  IconMenuButton,
  OpenNavButton,
  SeparationLine,
  StyledAppBar,
  StyledDrawer,
  StyledIconButton,
  StyledToolbar,
} from './AppMenu.styled';
import AppSearch from './AppSearch';

const AppMenu = () => {
  const dispatch = useOmsDispatch();
  const navigate = useNavigate();
  const resetStore = useResetStore();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<Nullable<HTMLElement>>(null);
  const [openMenu, setOpenMenu] = useState(false);

  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState<Nullable<HTMLElement>>(null);

  const isAdminView = useOmsSelector(SettingsSelectors.getIsAdminView);
  const isFlagsDoneFetching = useOmsSelector(FeatureFlagSelectors.isFlagsDoneFetching);
  const shouldShowDataOnlyUi = useOmsSelector(FeatureFlagSelectors.shouldShowDataOnlyUi);
  const userId = getUserId();
  const viewMode = useOmsSelector(SettingsSelectors.getViewMode);
  const sectionTitle = useOmsSelector(getSectionTitle);

  const location = useLocation();
  const isHidden =
    location.pathname === '/production/palletsqrcodes' ||
    location.pathname === '/production/brushsqrcodes/';
  const menu = useOmsSelector(TeammatesSelectors.getMenuAccess);

  useEffect(() => {
    dispatch(TeammatesActions.fetchSignedInTeammate());
  }, [dispatch, userId]);

  const handleOpenMenu = event => {
    setMenuAnchorEl(event.target);
    setOpenMenu(true);
  };

  const handleCloseMenu = event => {
    setSubMenuAnchorEl(null);
    event.preventDefault();
  };

  if (isHidden) return null;

  const fillListItem = menuItem => {
    if (menuItem.subMenu) {
      return (
        <div key={menuItem.name} data-testid={menuItem.dataTestId}>
          <Menu
            anchorEl={subMenuAnchorEl}
            open={Boolean(subMenuAnchorEl)}
            onClose={handleCloseMenu}
            onClick={() => setSubMenuAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            slotProps={{
              paper: {
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 2.0,
                  ml: 4.0,
                  '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    left: -5,
                    bottom: 25,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              },
            }}
          >
            {map(
              subMenu => (
                <MenuItem
                  key={subMenu.name}
                  data-testid={subMenu.dataTestId}
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    setSubMenuAnchorEl(event.currentTarget);
                    navigate(subMenu.to);
                  }}
                >
                  {subMenu.name}
                </MenuItem>
              ),
              menuItem.subMenu
            )}
          </Menu>
          <Tooltip title={menuItem.name}>
            <StyledIconButton onClick={e => setSubMenuAnchorEl(e.currentTarget)}>
              {menuItem.icon}
            </StyledIconButton>
          </Tooltip>
        </div>
      );
    }
    return (
      <ListItem
        key={menuItem.name}
        data-testid={menuItem.dataTestId}
        component={Link}
        to={menuItem.to}
      >
        <ListItemIcon>{menuItem.icon}</ListItemIcon>
        <ListItemText primary={menuItem.name} />
      </ListItem>
    );
  };

  return (
    <>
      <StyledAppBar>
        <StyledToolbar disableGutters={!isAdminView} sectiontitle={sectionTitle}>
          {!isAdminView && (
            <Link
              to={VIEW_MODE[viewMode]?.link}
              data-testid="link-to-lab"
              onClick={event => {
                dispatch(ProductionActions.emptyBox());
                event.currentTarget.blur();
              }}
            >
              <IconMenuButton color="inherit" aria-label="open drawer">
                <HomeIcon />
              </IconMenuButton>
            </Link>
          )}
          <AppBarSectionTitle>
            <Typography variant="h6" color="inherit" noWrap data-testid="appBar-title">
              {sectionTitle}
            </Typography>
          </AppBarSectionTitle>
          <AppBarSearch>
            <AppSearch />
          </AppBarSearch>
          <AppBarInfo>
            {isAdminView && (
              <>
                <InfoIcon fontSize="inherit" />
                <AppBarVersionInfo data-sentry-unmask>
                  <Typography variant="body1" color="inherit">
                    BACKOFFICE v{getEnv('REACT_APP_VERSION_BACKOFFICE')}
                  </Typography>
                  <Typography variant="body1" color="inherit">
                    API v{getEnv('REACT_APP_VERSION_API')}
                  </Typography>
                </AppBarVersionInfo>
              </>
            )}
            <AccountIcon data-testid="nav-user-menu" onClick={handleOpenMenu} fontSize="inherit" />
            <Menu
              id="simple-menu"
              anchorEl={menuAnchorEl}
              open={openMenu}
              onClose={() => setOpenMenu(false)}
              data-sentry-unmask
            >
              <MenuItem
                component={Link}
                data-testid="nav-user-account"
                onClick={() => setOpenMenu(false)}
                to="/teammates/me"
              >
                My account
              </MenuItem>
              <MenuItem
                data-testid="nav-user-settings"
                component={Link}
                onClick={() => setOpenMenu(false)}
                to="/settings"
              >
                Settings
              </MenuItem>
              <SeparationLine />
              <MenuItem
                component={Link}
                onClick={() => {
                  signout();
                  resetStore();
                }}
                to={`/auth/signin?next=${encodeURIComponent('/')}`}
                replace
              >
                Signout
              </MenuItem>
            </Menu>
            <Typography id="sectionTitle" variant="h6" color="inherit" noWrap />
          </AppBarInfo>
        </StyledToolbar>
      </StyledAppBar>
      {isAdminView && (
        <StyledDrawer variant="permanent" open={openDrawer}>
          <OpenNavButton
            onClick={() => setOpenDrawer(prevOpenDrawer => !prevOpenDrawer)}
            aria-label="open drawer"
          >
            {openDrawer ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </OpenNavButton>

          {/* MENU */}
          <List>
            {(!isFlagsDoneFetching || !shouldShowDataOnlyUi) && constructMenu(menu, fillListItem)}
          </List>
        </StyledDrawer>
      )}
    </>
  );
};

export default AppMenu;
