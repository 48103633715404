import type { SerializedError } from '@reduxjs/toolkit';

export type SigninForm = {
  target: {
    username: { value: string };
    password: { value: string };
  };
};

export function isSerializedError(err: unknown): err is SerializedError {
  return (err as SerializedError).message !== undefined;
}
